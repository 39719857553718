<template>
    <div v-click-outside="() => (this.showNotify = false)">
        <div class="bg-white p-1 rounded-full cursor-pointer relative">
            <div class="flex items-center" @click="showNotify = true">
                <BellIcon v-if="type !== 'lead'" class="w-7 h-7 mr-1 last:mr-0" />
                <MessageSquareIcon v-if="type === 'lead'" class="w-7 h-7 mr-1 last:mr-0" />
                <div v-if="count" class="w-6 rounded-full bg-red-500 text-white text-center font-bold text-12">
                    <small>{{ count }}</small>
                </div>
            </div>
            <div v-show="showNotify">
                <NotificationsModal
                    class="absolute top-[44px] right-[-40px] w-[432px] overflow-hidden"
                    :isShowOnlyNotReadNotifications="isShowOnlyNotReadNotifications"
                    @on-change-only-not-read="changeOnlyNotRead"
                    @close="showNotify = false"
                >
                    <template v-slot:content>
                        <div class="min-h-[400px] flex flex-col">
                            <template v-if="notifications && notifications.length">
                                <div class="flex justify-between mb-2">
                                    <button
                                        v-if="order_created_at === 'asc'"
                                        type="button"
                                        @click="order_created_at = 'desc'"
                                    >
                                        показать снчала новые
                                    </button>
                                    <button
                                        v-if="order_created_at === 'desc'"
                                        type="button"
                                        @click="order_created_at = 'asc'"
                                    >
                                        показать снчала старые
                                    </button>
                                    <button type="button" @click="readAllNotifications">
                                        Отметить все как прочитанное
                                    </button>
                                </div>
                                <div
                                    class="flex relative flex-col gap-3 bg-blue-100 px-4 py-2 -mx-3 max-h-[400px] custom-scroll overflow-auto"
                                    @scroll="onScroll"
                                >
                                    <div
                                        v-if="fetch_paginate"
                                        class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center disabled opacity-40"
                                    >
                                        <Preloader />
                                    </div>
                                    <div v-for="notify in mapNotifications">
                                        <div class="flex justify-between">
                                            <GroupNotifications
                                                class="flex-1"
                                                @onClickNotify="handleClickMessage(notify)"
                                                style="max-width: 364px; width: 100%"
                                                v-if="notify.type === 'group'"
                                                @more="getMoreGroupItem(notify.id)"
                                                :notify="notify"
                                                :key="notify.id"
                                            />
                                            <div v-else class="bg-gray-300" style="max-width: 364px; width: 100%">
                                                <Notify
                                                    class="py-2 block"
                                                    @click="handleClickMessage(notify)"
                                                    :notify="notify"
                                                    :show_border="false"
                                                    :key="notify.id"
                                                />
                                            </div>

                                            <button
                                                class="mr-2 mt-2 w-5 h-5 bg-blue-600 rounded-full transition-all cursor-pointer"
                                                :class="{ 'bg-opacity-40': !notify.read }"
                                                @click="
                                                    async (event) => {
                                                        event.target.disabled = true;
                                                        try {
                                                            await readNotify(notify.id, notify.type);
                                                        } catch (e) {}
                                                        event.target.disabled = false;
                                                    }
                                                "
                                            ></button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div v-else class="flex flex-col gap-8 items-center justify-center w-full flex-[1] mb-10">
                                <NoNotificationsImage />
                                <p class="text-xl mb-0">
                                    {{
                                        getIsShowOnlyNotReadNotifications
                                            ? 'Нет непрочитанных уведомлений'
                                            : 'Нет уведомлений'
                                    }}
                                </p>
                            </div>
                        </div>
                    </template>
                </NotificationsModal>

                <ModalJntUser
                    v-if="modalUser"
                    :user-id="userId"
                    v-on:close="modalUser = false"
                    v-on:update="updateUser"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import useFaviconBadgeNotify from 'favicon-badge-notify';
import { errorResponse } from '@/mixins/form/form-mixin';
import NotificationsModal from '@/components/modals/notifications/NotificationsModal';
import UserNotification from '@/components/notifications/UserNotification';
import NoNotificationsImage from '@/components/icons/NoNotificationsImage';
import ModalJntUser from '@/components/modals/modals-users/ModalJnt';
import factoryMessageByNotification from '@/views/users/notifications/Mappers/factory-message-by-notification';
import store from '@/store';
import dayjs from 'dayjs';
import debounce from 'debounce';
import Preloader from '@/components/preloader/Main';
import UserNotificationDeal from '@/components/notifications/UserNotificationDeal.vue';
import Notify from '@/views/users/notifications/Notify.vue';
import GroupNotifications from '@/views/users/notifications/GroupNotifications.vue';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'UserNotifications',
    components: {
        GroupNotifications,
        UserNotificationDeal,
        NotificationsModal,
        UserNotification,
        NoNotificationsImage,
        ModalJntUser,
        Preloader,
        Notify,
    },
    mixins: [errorResponse],
    props: {
        type: {
            default: 'deal',
        },
    },
    async mounted() {
        this.audio = new Audio('/notification.mp3');
        const { drawBadge, destroyBadge } = await useFaviconBadgeNotify({
            src: '/icon.png',
        });
        this.drawBadge = drawBadge;
        this.destroyBadge = destroyBadge;

        await this.fetchData(
            this.getIsShowOnlyNotReadNotifications ? 1 : 0,
            this.order_created_at,
            this.page,
            this.type
        );

        const count_response = await this.axios.get(`notification/count?type=${this.type}`);
        this.count_notify = count_response.data.count;
        if (this.type === 'deal') {
            const { data } = await this.axios.get('/notification/count-deal-unread');
            store.commit('user/setUnreadDeal', data);
        }

        this.chanel = window.Echo.private('App.Models.User.' + store.getters['user/getUser'].id)
            .listen('NewNotificationEvent', async (event) => {
                if (
                    (this.type === 'lead' && event.data.type === 'create_lead') ||
                    (this.type === 'deal' && event.data.type !== 'create_lead')
                ) {
                    this.count_notify += 1;

                    const notification_index = this.notifications.findIndex((item) => item.id === event.data.id);
                    const this_date = dayjs(
                        event.data.type === 'group' ? event.data.updated_at : event.data.created_at
                    );

                    for (const index in this.notifications) {
                        const notify = this.notifications[index];
                        const sort_date = notify.type === 'group' ? notify.updated_at : notify.created_at;
                        if (dayjs(sort_date) <= this_date) {
                            if (notification_index !== -1) {
                                this.notifications.splice(notification_index, 1);
                            }
                            this.notifications.splice(index, 0, event.data);
                            break;
                        }
                    }
                    if (event.data.type === 'group') {
                        store.commit('user/setUnreadDealFlag', {
                            deal_id: event.data.entity_id,
                            is_read: event.data.is_unread,
                        });
                    }
                    if (this.notifications.length === 0) {
                        this.notifications.push(event.data);
                    }

                    if (!this.is_played && this.getIsSoundNotify) {
                        this.is_played = true;
                        try {
                            if (event.sound) {
                                const audio = new Audio(event.sound);
                                await audio.play();
                            } else {
                                await this.audio.play();
                            }
                        } catch (e) {
                            console.warn('user dont use document');
                        }
                        setTimeout(() => (this.is_played = false), 300);
                    }
                }
            })
            .listen('UpdatedDeal', (event) => {
                if (this.type === 'deal') {
                    const data = event.data;
                    const notify = this.notifications.find(
                        (notify) => notify.type === 'group' && notify.deal_id == data.id
                    );
                    if (notify) {
                        notify.deal_name = data.name;
                        notify.deal_date = {
                            start_date: data.start_date,
                            end_date: data.end_date,
                            status: data.status,
                        };
                    }
                }
            })
            .listen('ReadedNotificationEvent', (event) => {
                if (
                    (this.type === 'lead' && event.type === 'create_lead') ||
                    (this.type === 'deal' && event.type !== 'create_lead')
                ) {
                    const notify = this.notifications.find((el) => el.id === event.notify_id);

                    if (notify) {
                        if (dayjs(notify.updated_at) <= dayjs(event.updated_at)) {
                            if (event.is_read) {
                                this.count_notify += event.count_read;
                                // store.commit('user/decrementUnreadDeal', event.payload.deal_id);
                            } else {
                                this.count_notify -= event.count_read;
                                // store.commit('user/incrementUnreadDeal', event.payload.deal_id);
                            }
                            if (event.type === 'group') {
                                notify.is_unread = event.is_read;
                                store.commit('user/setUnreadDealFlag', {
                                    deal_id: notify.entity_id,
                                    is_read: notify.is_unread,
                                });
                            } else {
                                notify.read_at = event.is_read;
                            }
                            // notify.updated_at = event.updated_at;
                            if (this.getIsShowOnlyNotReadNotifications) {
                                const index = this.notifications.findIndex((el) => el.id === notify.id);
                                this.notifications.splice(index, 1);
                            }
                        }
                    } else {
                        if (event.is_read) {
                            this.count_notify += event.count_read;
                        } else {
                            this.count_notify -= event.count_read;
                        }
                    }
                    if (this.count_notify < 0) {
                        this.count_notify = 0;
                    }
                }
            })
            .listen('ReadedAllNotificationEvent', (event) => {
                const date = $h.getDayjs(event.date);

                if (event.type === this.type) {
                    this.notifications.forEach((notify) => {
                        if (notify.type === 'group') {
                            if (notify.is_unread) {
                                const length_notify = notify.notifications.data.length;
                                let length_mark_read = 0;
                                const splice_in_more = {};

                                notify.notifications.data.forEach((item, index) => {
                                    if ($h.getDayjs(item.created_at) <= date) {
                                        item.read_at = true;
                                        splice_in_more[index] = item;
                                        length_mark_read += 1;
                                    }
                                });

                                if (length_notify === length_mark_read) {
                                    notify.is_unread = false;
                                    store.commit('user/setUnreadDealFlag', {
                                        deal_id: notify.entity_id,
                                        is_read: false,
                                    });
                                } else {
                                    Object.keys(splice_in_more)
                                        .reverse()
                                        .forEach((key) => {
                                            notify.notifications.data.splice(key, 1);
                                            notify.more.unshift(splice_in_more[key]);
                                        });
                                }
                            }
                        } else {
                            notify.read_at = false;
                        }
                    });
                    const count_notify = this.count_notify - event.count_readed;

                    if (count_notify < 0) {
                        this.count_notify = 0;
                    } else {
                        this.count_notify = count_notify;
                    }
                }
                if (event.type === 'deal') {
                    store.commit('user/setUnreadDeal', event.unreaded_deal);
                }
            })
            .listenForWhisper('ChangedReadNotify', async (event) => {
                if (this.type === event.type && Number(event.value) !== this.isShowOnlyNotReadNotifications) {
                    this.isShowOnlyNotReadNotifications = Number(event.value);
                    localStorage.setItem(
                        'show_only_not_read_notifications',
                        Number(this.isShowOnlyNotReadNotifications)
                    );
                    await this.fetchData(
                        this.isShowOnlyNotReadNotifications,
                        this.order_created_at,
                        this.page,
                        this.type
                    );
                }
            });
    },
    data() {
        return {
            page: 1,
            has_more: false,
            isShowOnlyNotReadNotifications: localStorage.getItem('show_only_not_read_notifications')
                ? JSON.parse(localStorage.getItem('show_only_not_read_notifications'))
                : 0,
            chanel: null,
            fetch_paginate: false,
            drawBadge: null,
            order_created_at: 'desc',
            destroyBadge: null,
            is_played: false,
            audio: null,
            userEdit: {},
            notifications: [],
            userId: 0,
            count_notify: 0,
            modalUser: false,
            showNotify: false,
        };
    },
    watch: {
        count_notify: function() {
            if (this.type !== 'lead') {
                const favicon = document.querySelector("link[rel~='icon']");
                this.drawBadge(this.count_notify).then((badge) => (favicon.href = badge));
                if (this.count_notify === 0) {
                    document.title = 'crm';
                    return;
                }

                document.title = `(${this.count_notify}) crm`;
            }
        },
        order_created_at: async function() {
            await this.fetchData(this.getIsShowOnlyNotReadNotifications ? 1 : 0, this.order_created_at, 1, this.type);
        },
    },
    computed: {
        ...mapGetters({
            getIsShowOnlyNotReadNotifications: 'user/getIsShowOnlyNotReadNotifications',
            getIsSoundNotify: 'user/getIsSoundNotify',
        }),
        getIsShowOnlyNotReadNotifications() {
            return this.isShowOnlyNotReadNotifications;
        },
        count() {
            const count = this.count_notify;

            return count >= 99 ? '99+' : count;
        },
        mapNotifications() {
            const map = [];

            if (this.notifications.length > 0) {
                this.notifications.forEach((notification) => {
                    let notify = null;
                    if (notification.type === 'group') {
                        notify = JSON.parse(JSON.stringify(notification));

                        notify.notifications.data = notify.notifications.data.map((notify_item) => {
                            const item = factoryMessageByNotification(notify_item);
                            item.read = Boolean(notify_item.read_at);
                            item.sender = notify_item.sender;

                            return item;
                        });

                        notify.more = notify.more.map((notify_more) => {
                            const item = factoryMessageByNotification(notify_more);
                            item.read = Boolean(notify_more.read_at);
                            item.sender = notify_more.sender;

                            return item;
                        });

                        notify.read = notify.is_unread;
                    } else {
                        const item = JSON.parse(JSON.stringify(notification));
                        notify = factoryMessageByNotification(item);
                        notify.read = item.read_at;
                        notify.sender = item.sender;
                        notify.created_at = item.created_at;
                        ``;
                        notify.updated_at = item.updated_at;
                    }
                    map.push(notify);
                });
            }
            // map.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
            return map;
        },
    },
    methods: {
        onScroll: debounce(async function(event) {
            const calculateScroll = event.target.scrollTop + event.target.clientHeight;
            /// есть проблема почему-то толлько при первой прокрутке нужно добавить +1 , но потом итс окей
            if (
                (event.target.scrollHeight === calculateScroll || event.target.scrollHeight === calculateScroll + 1) &&
                !this.fetch_paginate
            ) {
                if (this.has_more) {
                    this.page += 1;
                    this.fetch_paginate = true;
                    await this.fetchData(
                        this.getIsShowOnlyNotReadNotifications ? 1 : 0,
                        this.order_created_at,
                        this.page,
                        this.type
                    );
                    this.fetch_paginate = false;
                }
            }
        }, 500),

        async getMoreGroupItem(group_id) {
            const group = this.notifications.find((item) => item.type === 'group' && item.id === group_id);
            if (group) {
                const meta = group.notifications.meta;
                const { data } = await this.axios.get('notification/group/item', {
                    params: {
                        limit: meta.per_page,
                        group_id: group_id,
                        page: meta.current_page + 1,
                    },
                });
                group.notifications.meta = data.data.meta;
                group.notifications.data.push(...data.data.items);
            }
        },

        async fetchData(is_read = 0, order_created_at = 'desc', page = 1, type = null) {
            const { data } = await this.axios.get(
                `/notification?is_read=${is_read}&order_created_at=${order_created_at}&page=${page}&type=${type}`
            );
            if (page === 1) {
                this.notifications = data.data;
                this.page = 1;
            } else {
                this.notifications.push(...data.data);
            }
            this.has_more = data.meta?.has_more;
        },
        async handleClickMessage(data) {
            const notify = this.notifications.find((notify) => notify.id === data.id);
            if (notify?.payload?.type === 'create_lead' && notify?.payload?.lead_id) {
                await this.$router.push({ name: 'leadEdit', params: { id: notify.payload.lead_id } });
                if (notify.read_at) {
                    await this.readNotify(notify.id, 'notification');
                }
            }
            if (notify?.payload?.type === 'attach_user_pipeline') {
                await this.$router.push({ name: 'funnel', params: { id: notify.payload.pipeline_id } });
                if (notify.read_at) {
                    await this.readNotify(notify.id, 'notification');
                }
            }
            if (notify?.payload?.type === 'detach_user_pipeline') {
                if (notify.read_at) {
                    await this.readNotify(notify.id, 'notification');
                }
            }
            if (notify?.type === 'group') {
                if (notify.is_unread) {
                    await this.readNotify(notify.id, 'group');
                }
            }
        },
        async changeOnlyNotRead(value) {
            this.isShowOnlyNotReadNotifications = Number(value);
            this.chanel.whisper('ChangedReadNotify', {
                value: value,
                type: this.type,
            });
            localStorage.setItem('show_only_not_read_notifications', Number(value));
            await this.fetchData(value, this.order_created_at, 1, this.type);
        },
        async readNotify(id, type = null) {
            const index = this.notifications.findIndex((item) => item.id === id);

            if (index !== -1) {
                let item = this.notifications[index];
                await this.axios.post(`notification/read/${id}`, {
                    type: type,
                    is_read: item.type === 'group' ? !item.is_unread : item.read_at,
                });

                if (type === 'group') {
                    item.is_unread = !item.is_unread;
                    store.commit('user/setUnreadDealFlag', {
                        deal_id: item.entity_id,
                        is_read: item.is_unread,
                    });
                    //     if (item.is_unread) {
                    //         this.count_notify += item.notifications.data.length;
                    //     } else {
                    //         this.count_notify -= item.notifications.data.length;
                    //     }
                    // } else {
                    //     item.read_at = !Boolean(item.read_at);
                    //     if (item.read_at) {
                    //         this.count_notify += 1;
                    //     } else {
                    //         this.count_notify -= 1;
                    //     }
                }
                if (this.getIsShowOnlyNotReadNotifications) {
                    this.notifications.splice(index, 1);
                } else {
                    this.notifications.splice(index, 1, item);
                }

                if (this.notifications.length === 0) {
                    await this.fetchData(
                        this.getIsShowOnlyNotReadNotifications ? 1 : 0,
                        this.order_created_at,
                        1,
                        this.type
                    );
                }
            }
        },
        async readAllNotifications() {
            try {
                const response = await this.axios.post('/notification/read-all', {
                    type: this.type,
                    date: $h.getDayjs().format(),
                });

                if (this.getIsShowOnlyNotReadNotifications) {
                    this.notifications = [];
                } else {
                    this.notifications.forEach((item) => {
                        if (item.type === 'group') {
                            item.is_unread = false;
                            store.commit('user/setUnreadDealFlag', {
                                deal_id: item.entity_id,
                                is_read: item.is_unread,
                            });
                        } else {
                            item.read_at = false;
                        }
                    });
                }
                this.count_notify = 0;
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        handleShowModalUser(user) {
            this.userEdit = user;
            this.userId = user.id;
            this.modalUser = true;
        },
        updateUser(data) {
            this.userEdit[this.typeUser] = data;
        },
    },
};
</script>

<style scoped lang="scss">
.custom-scroll {
    scrollbar-width: auto;
    scrollbar-color: rgba(96, 111, 196, 0.97);
}

.custom-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(96, 111, 196, 0.97);
    border-radius: 9em;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: rgba(147, 157, 211, 0.97);
}

.day-notify {
    color: white;
    font-weight: 800;
    display: inline-block;
    padding: 4px;
    border-radius: 8px;
    background-color: rgba(30, 44, 58, 0.8);
}

.wrapper-day-notify {
    position: sticky;
}

.notify:last-child {
    margin-bottom: 0;
}

.color-user {
    color: #172b4d;
}
</style>
